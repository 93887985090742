<template>
    <v-container fluid>
      <v-row dense v-if="!selectedProject">
        <v-col
          xs="12" sm="6" md="4" lg="3" xl="2" cols="12"
          align="center"
          v-for="(project, index) in projects"
          :key="index"
          @click="selectProject(project)"
        >
          <ProjectCard
            :title="project.title"
            :image="project.image"
            :description="project.description"
            :url="project.url"
          />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          xs="12" cols="12"
          justify="center"

        >
          <ProjectDetails
            :title="selectedProject.title"
            :image="selectedProject.image"
            :description="selectedProject.description"
            :url="selectedProject.url"
            :items="selectedProject.projectItems"
            @close="deselectProject"
          />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
<script>
    import ProjectCard from '@/components/ProjectCard';
    import ProjectDetails from '@/components/ProjectDetails';
    import projectsData from '@/data/Projects';
    import { defineComponent } from 'vue';
    export default defineComponent( {

        name: 'SelectProjectsView',
        components: {
            ProjectCard,
            ProjectDetails
        },
        data() {
            return {
            projects: projectsData,
            selectedProject: null
            };
        },
        methods: {
            selectProject(project) {
            this.selectedProject = project;
            },
            deselectProject() {
            this.selectedProject = null;
            }
        }
    });
</script>
  