<template>

    <v-container class="fill-height" >
        <v-row justify="center">
            <v-btn
                icon="mdi-linkedin"
                class="mx-4"
                :href="linkedIn"
                target="_blank"
            ></v-btn>
            
        </v-row>
        <v-row class="mt-8 mb-2" justify="center">
            <div class="text-body-1" align="center">Email: {{this.email}} </div>
        </v-row>
        <v-row class="my-10" justify="center">
            <div class="text-h4 font-weight-bold" align="center">{{this.name}}</div>
        </v-row>
        <v-row class="my-10" justify="center">
            <div class="text-body-1" >{{this.aboutText}}</div>
        </v-row>

        <v-row class="mt-5" justify="center">
            
            <v-col cols="3" justify="center">
                <v-btn
                    width="70%"
                    prepend-icon="mdi-file-pdf-box"
                    justify="center" align="center"
                    color="pdf"
                    text-color="white"
                    class="mx-4"
                    :href="pdfEn"
                    target="_blank"
                >{{$t('message.download_cv_en')}}</v-btn>
            </v-col>
            <v-col cols="3">
            <v-btn
                prepend-icon="mdi-file-pdf-box"
                width="70%"
                text-color="white"
                align="center"
                color="pdf"
                class="mx-4"
                :href="pdfEs"
                target="_blank"
            >{{$t('message.download_cv_es')}}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent} from 'vue';




export default defineComponent({
    name: 'PersonInfo',
    props: ['name', 'email', 'linkedIn', 'pdfEn', 'pdfEs', 'aboutText' ,'description'],
})

</script>