<template>
    <v-card
      class="mx-4 mt-4"
      max-width="400"
      style="width: 400px; height: 200px;"
    >
      <v-card-title>
        <v-icon large left :icon="this.icon" color="#0357D1"/>
        <span class="text-h5 font-weight-bold ml-2">{{this.title}}</span>
      </v-card-title>
  
      <v-card-text class="text-body-1 font-weight-light">
        {{this.description}}
      </v-card-text>
    </v-card>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SkillCard',
    props: ['title', 'icon', 'description'],
})
</script>