<template>
  <div class = "bg-surface-bright avatar-section">
    <v-row class="text-h4 text-grey mt-5 mb-5" align-content="center" justify="center" >
    {{ $t('message.about_us') }}
  </v-row>

    <v-row>
      <v-col cols="6" class="avatar-container">
        <v-img contain src="@/assets/EugeniaKlein.jpeg" class="avatar-person2" 
                
                @click="toggleTimeline('person2')"
                :class="{ 'selected': showTimeline('person2') }"
                @mouseover="hoverPerson('person2')"
                @mouseleave="unhoverPerson('person2')"/>
        </v-col>

        <v-col cols="6" class="avatar-container">
          <v-img contain src="@/assets/JoseMiche.png" class="avatar-person1" 
          
              @click="toggleTimeline('person1')"
              :class="{ 'selected': showTimeline('person1') }"
              @mouseover="hoverPerson('person1')"
              @mouseleave="unhoverPerson('person1')"/>
        
          
        </v-col>
    </v-row>
  
  </div>
  
  <div class="bg-surface-dark">
    <v-container class="bg-surface-dark">
      
      <div class="text-h5 mt-15" justify="center" v-if = "!showTimeline('person1') && !showTimeline('person2')">
  
        {{ $t('message.about_us_text') }}
      </div>
      <div>
        
        
        
    <div v-if="showTimeline('person1')">
        <!-- Timeline for person 1 -->

        
        <v-row justify="center" class="my-5">
          <PersonInfo :name="michelini.fullname"
          :description="'Description'"
          :email="michelini.mail"
          :pdfEn="michelini.pdfEn"
          :pdfEs="michelini.pdfEs"
          :aboutText="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'" 
          :linkedIn="michelini.linkedIn"/>
          
        </v-row>
        
        <v-row justify="center" class="mb-10">
            <timeline :projects="michelini.projects"/>
        </v-row>


    </div>

      <div v-if="showTimeline('person2')">

        <v-row justify="center" class="my-5">

        <PersonInfo :name="klein.fullname"
                    :description="'Description'"
                    :email="klein.mail"
                    :pdfEn="klein.pdfEn"
                    :pdfEs="klein.pdfEs"
                    :aboutText="'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'" 
                    :linkedIn="klein.linkedIn"/>

        </v-row>
      
        <v-row justify="center">
          <timeline :projects="klein.projects" class="mb-15"/>
        </v-row>

      </div>

      <div v-if="!showTimeline('person2') && !showTimeline('person1')" style="height: 70px; width: 400px;">
        
      </div>

    </div>


  </v-container>



</div>



  </template>

  
  <script>

import { defineComponent } from 'vue';
import Timeline from '../components/Timeline.vue'
import PersonInfo from '../components/PersonInfo.vue'
import Footer from '../components/Footer.vue'
import micheliniData from '@/data/Michelini';
import kleinData from '@/data/Klein';

  export default defineComponent( {
    data() {
      return {
        showPerson1Timeline: false,
        showPerson2Timeline: false,

        michelini: micheliniData,
        klein: kleinData,
        
        person1Projects: micheliniData.projects,
        person2Projects: kleinData.projects,
      };
    },
    methods: {
        toggleTimeline(person) {
       
            if (person === 'person1') {


                
                this.showPerson1Timeline = !this.showPerson1Timeline;
                this.showPerson2Timeline = false; // Collapse other timeline
            } else if (person === 'person2') {
                this.showPerson2Timeline = !this.showPerson2Timeline;
                this.showPerson1Timeline = false; // Collapse other timeline
            }
        },
        showTimeline(person) {
            if (person === 'person1') {
                return this.showPerson1Timeline;
            } else if (person === 'person2') {
                return this.showPerson2Timeline;
            }
            return false;
        },
        hoverPerson(person) {

            const avatar = document.querySelector(`.avatar-${person}`);
            if (avatar) {
                avatar.size = 80;
                avatar.classList.add('hovered');
            }
        },
        unhoverPerson(person) {
            const avatar = document.querySelector(`.avatar-${person}`);
            if (avatar) {
                avatar.size = 40;
                avatar.classList.remove('hovered');
            }
        },
    },
    components: {
      Timeline,
      PersonInfo,
      Footer
    },

  });
  </script>

<style scoped>

    .avatar-section {
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .avatar-container {
        display: flex;
        justify-content: center; /* Horizontally center */
        align-items: center; /* Vertically center */
    }

    .v-img {
    transition: transform 0.3s ease;
    filter: grayscale(100%);
    width: 200px;
    height: 200px;
    }

    .selected{
        transform: scale(1.0);
        filter: grayscale(0%);
    }


    .name-tile{
        font-weight: bold;
    }


    .hovered {
    /* border: 2px solid white; Set the border color and thickness */
    transform: scale(1.0);
    filter: grayscale(0%);
    }

    .hovered img {
    filter: grayscale(0%);
    }

    .hovered:hover img {
    filter: grayscale(0%);
    } 

    


</style>
  