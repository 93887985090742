import {createI18n} from 'vue-i18n';

const messages = {
    en: {
      message: {
        darwin_quote: '"It is not the strongest of species that survives, nor the most intelligent, but the one most responsive to change." - Charles Darwin',
        quote_reflexion: "Finding in the crises, in the breaks, in the changes that often make us dizzy, the meaning of what is still emerging is our strength. Naming what screams from silence. People make companies and vice versa. Incorporating flexibility, humanity, intuition and perseverance is what allows adaptability to a world that is full of uncertainties.",
        about_us: "About us",
        about_us_text: 'We are two professionals who met looking for the meaning of the present. Who come together to accompany you on the path of transformation, of being the change and not letting the change overwhelm you.',
        our_programs: 'Our workshops',
        download_cv_en: 'English CV',
        download_cv_es: 'Spanish CV',
        download_thesis: 'Download Thesis PDF',
        now: 'now'
      }
    },
    es: {
      message: {
        darwin_quote: '"No es la especie más fuerte la que sobrevive, ni la más inteligente, sino la que responde mejor al cambio." - Charles Darwin',
        quote_reflexion: "Encontrar en las crisis, en los quiebres, en los cambios que muchas veces nos marean, el sentido de lo que aún está emergiendo es nuestro fuerte. Ponerle nombre a lo que grita desde el silencio. Las personas son las que hacen a las empresas y viceversa. Incorporar flexibilidad, humanidad, intuición y constancia es lo que permite la adaptabilidad a un mundo que se presenta lleno de incertidumbres. ",
        about_us: "Sobre nosotras",
        about_us_text: 'Somos dos profesionales que se encontraron buscando el sentido del presente. Que se unen para acompañarlos en el camino de la transformación, de ser el cambio y no que el cambio los arrolle.',
        our_programs: 'Nuestros programas',
        download_cv_en: 'CV Inglés',
        download_cv_es: 'CV Español',
        download_thesis: 'Descargar Tesis PDF',
        now: 'ahora'
      }
    }
  };
  
  const vuei18n = createI18n({
    locale: 'en',
    messages: messages
  });
  
  export default vuei18n