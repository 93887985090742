<template>
    <v-container fluid>
        <v-row dense>
          <v-col xs="12" sm="6" md="4" lg="3" xl="2" cols="12" align="center" v-for="project in projects">
              <ProjectCard :title=project.title :image=project.image :description=project.description :url=project.url />
          </v-col>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import ProjectCard from '../components/ProjectCard.vue'
  
  
  // Components
  
  export default defineComponent({
    name: 'ProjectsView',
    components: {
      ProjectCard
    },
    data () {
      return {
        projects: [
            {
                title: 'MENTORÍA (LÍDERES y EQUIPOS)', 
                image: 'bg.jpeg' ,
                description: 'Mentoría CC: comercial y mandos medios.  Mentoría JM: liderazgo, toma de decisiones, construcción de equipos',
                url: 'https://www.linkedin.com/in/cecilia-casas-1a1b1b1b/'
            
            },

            {
                title: 'COACHING', 
                image: 'bg.jpeg' ,
                description: 'JM: directores y ejecutivos / CC: mandos medios (equipos o procesos)',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            },

            {
                title: 'WORKSHOPS A MEDIDA', 
                image: 'bg.jpeg' ,
                description: 'Estrategia organizacional - Evaluación de desempeño - Conversaciones de desarrollo - Expectativas y Feedback  - Gestión del talento - Clima organizacional',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            },

            {
                title: 'ACOMPAÑAMIENTO A EMPRESAS EN PROCESO DE TRANSFORMACIÓN', 
                image: 'bg.jpeg' ,
                description: 'Empresas familiares - protocolo de sucesión/ acuerdos  nuevas generaciones - Implementación de nuevos sistemas - Nuevos dueños',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            }
            
        ]
      }
    }
  });

</script>




