 <template>
    
        

            <div class="timeline-container" style="overflow-x: auto;">
            <v-timeline side="end" class="mr-5" direction="horizontal">
                <v-timeline-item
                    v-for="project in projects"
                    :key="project.title"
                    size="90"
                >
                    <template v-slot:icon>
                        <v-avatar size="80">
                            <img :src="require('@/assets/' + project.image)" style="width: 80px"/>
                        </v-avatar>
                    </template>
                    <TimelineCard :title="project.title" :timeRange="project.timeRange" :content="project.description"/>
                </v-timeline-item>
            </v-timeline>
       </div>
   
</template>

<script>
import { defineComponent } from 'vue';
import TimelineCard from '../components/TimelineCard.vue'

export default defineComponent ({
    name : 'Timeline',
    props: ['projects'],
    components: {
        TimelineCard
    }
});

</script> 

<style>

</style>


