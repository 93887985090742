<!-- <template>
  <div :style="{'backgroundImage': 'url(' + image + ')', height: '100%', backgroundPosition: 'center'}">
  <v-container style="height:70vh">
    <v-row justify="center" align="center" class="mt-5">
      <v-col md="12" class="d-flex justify-center">
          <v-img contain :max-width="width" src="@/assets/artistic.jpeg" class="rounded-circle" :style="imageBorderStyle"/>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-10">
      <div :style="nameStyle" :class="titleSize + ' font-weight-bold'">Josefina Michelini</div>
    </v-row>
    <v-row justify="center" align="center" class="mt-5">
      <div :style="nameStyle" :class="subtitleSize">{{$t('message.software_engineer')}}</div>
    </v-row>
  </v-container>
</div>

</template> -->

<template>
  <v-container fluid class="fill-height empty-space">

    <v-row fluid class ="fill-height">

        <v-col cols = "6" class=" empty-space2" align-self="center">
        Dos
        </v-col>

        <v-col cols = "6" class=" empty-space3 text-white"  align-self="center">
         Letras
        </v-col>

    </v-row>



  </v-container>
</template>






<style>
.empty-space {
  height: fill; /* Adjust the height as needed */
  /* background-color: rgb(var(--v-theme-surface)); */
  font-size: 70px;
  line-height: 2.25rem;
  font-family: 'Roboto';
  letter-spacing: 0.0892857143em !important;
  font-weight: 500 !important;
  align-items: center;


}

.empty-space3 {
  align-items: center;
  justify-content: left;
  display: flex;

  height: fill; /* Adjust the height as needed */
  background-color: rgb(var(--v-theme-surface));
  font-size: 70px;
  line-height: 2.25rem;
  font-family: 'Roboto';
  font-weight: 500 !important;
  letter-spacing: 0.0892857143em !important;
  color: black
}




.text-button {
  font-size: 150px !important;
}

.empty-space2 {
  align-items: center;
  justify-content: right;
  display: flex;

  height: fill; /* Adjust the height as needed */
  background-color: rgb(var(--v-theme-background));
  font-size: 70px;
  line-height: 2.25rem;
  font-family: 'Roboto';
  font-weight: 500 !important;
  letter-spacing: 0.0892857143em !important;
}



</style>

<script>
import { defineComponent } from 'vue';
import { useDisplay } from 'vuetify'
import { computed } from 'vue'

// Components

export default defineComponent({
  name: 'HomeView',
  data () {
    return {
      image: require('../assets/bg.jpeg')
    }
  },


  setup () {
    const { name } = useDisplay()
    console.log(name)

    const width = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs': return 200
        case 'sm': return 250
        case 'md': return 300
        case 'lg': return 400
        case 'xl': return 500
        case 'xxl': return 500
      }

      return undefined
    })

    const titleSize = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs': return 'text-h3'
        case 'sm': return 'text-h3'
        case 'md': return 'text-h3'
        case 'lg': return 'text-h3'
        case 'xl': return 'text-h3'
        case 'xxl': return 'text-h3'
      }

      return undefined
    })

    const subtitleSize = computed(() => {
      // name is reactive and
      // must use .value
      switch (name.value) {
        case 'xs': return 'text-h6'
        case 'sm': return 'text-h4'
        case 'md': return 'text-h3'
        case 'lg': return 'text-h4'
        case 'xl': return 'text-h4'
        case 'xxl': return 'text-h4'
      }

      return undefined
    })

    const imageBorderStyle = computed(() => ({

      border: `0.5vw solid black` // Use your desired theme color
    }))

    const nameStyle = computed(() => ({
      color: `white`,

      // border: `0.5vw solid white` // Use your desired theme color
    }))

    

    return { width, titleSize, subtitleSize, imageBorderStyle, nameStyle }
  }
});
</script>
