<template>
    <div>
      <v-card color="surface-dark" align="center" align-items="center" justify="center">
        <div class="image-container">
            <div class="image" :style="{ backgroundImage: 'url(' + require('@/assets/' + image ) + ')' }"></div>
            <v-card-actions>
            <v-btn @click="$emit('close')" style="background-color:black; color: white;">Other workshops</v-btn>
            </v-card-actions>
        </div>
        <div class="details-content">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>{{ description }}</v-card-text>

        <v-row justify="center">

        <v-col class="mb-5 mt-5" xs="12" sm="6" md="4" lg="3" xl="2" cols="12" align="center" v-for="item in items">
            <v-card color="surface-light" width="300px" >
                <v-card-item>
                    <v-img cover :src="require('@/assets/' + item.image )" height="200px"></v-img>
                    <div>
                        <div class="text-h6 mb-1">
                            {{item.title}}
                        </div>
                        <div class="text-caption">{{ item.description }}</div>
                    </div>
                </v-card-item>
            </v-card>
        </v-col>
         </v-row>
        </div>
        <br>
      </v-card>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      image: String,
      description: String,
      url: String,
      items: Array
    },
    methods: {
      openUrl() {
        window.open(this.url, '_blank');
      }
    }
  };
  </script>


<style scoped>

.details-content {
  text-align: center; /* Centering the text horizontally */
}


.project-details-card {
  position: relative;
}

.image-container {
  position: relative;
  height: 300px; /* Adjust the height as needed */
  overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
</style>
  