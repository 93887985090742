<template>
<v-card class="elevation-2 mr-5" style="min-width: 400px; max-width:500px">
    <v-card-title class="timeline-card-title">
      {{this.title}}
    </v-card-title>
    <v-card-subtitle class="">
      {{$t(this.timeRange)}}
    </v-card-subtitle>
    <v-card-text>
      {{$t(this.content)}}
    </v-card-text>
  </v-card>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TimelineCard',
    props: ['title', 'timeRange', 'content'],
})
</script>

<style>
.timeline-card-title {
    font-size: 15px;
    font-weight: bold;
}

</style>