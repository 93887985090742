<template>

  <v-container flex>

    <v-row>

      <v-col cols="12">
        <div class="fancy-title">{{$t('message.darwin_quote') }}</div>
        <p class="text-h7">
          {{ $t('message.quote_reflexion') }}
        </p>
      </v-col>

    </v-row>

  </v-container>

  
  
  
  <TimelineView></TimelineView>
  
  <div class="bg-surface">
    <br>
    <v-row class="text-h4 text-grey mb-5" align-content="center" justify="center" >
      {{ $t('message.our_programs') }}
    </v-row>
    
    <SelectProjectView></SelectProjectView>
  </div>

  <Footer></Footer>
    
</template>

<script>
import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import TimelineView from './TimelineView.vue';
import SelectProjectView from './SelectProjectView.vue';
// Components

export default defineComponent({
    name: 'AboutView',
    components: {Footer, TimelineView, SelectProjectView},
  

});
</script>


<style>
/* In your main CSS file or in the <style> section of your Vue component */
  .text-wrap {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .fancy-title {
    font-size: 2.0rem;
    font-weight: 300;
    margin-bottom: 1rem;
    font-family: 'Roboto';
    font-style: italic;
  }

</style>