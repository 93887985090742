const micheliniData = {
    fullname: 'Josefina Michelini',
    name: 'Josefina',
    surname: 'Michelini',
    mail: 'josefinamichelini@gmail.com',
    projects: [
        {
            title: 'IAE Business School',
            timeRange: '2001 - 2012',
            description: 'Researcher - Outdoors - Investigadora Asociada, Centro RUHO Talento y Management en LATAM',
            image: 'IAE_logo.png',
        },

        {
            title: 'Happiness Works',
            timeRange: '2014 - 2016',
            description: 'Practicioner',
            image: 'IAE_logo.png',
        },

        {
            title: 'Municipalidad de Pilar',
            timeRange: '2016 - 2017',
            description: 'Directora de Integración Institucional, Secretaría de Educación',
            image: 'argentina.png',
        },

        {
            title: 'wwconsultores',
            timeRange: '2013 - 2018',
            description: 'Directora',
            image: 'wwconsultores.jpeg',
        },
        {
            title: 'Honorable Cámara de Diputados de la Nación',
            timeRange: '2017 - 2021',
            description: 'Jefa de Asesores',
            image: 'argentina.png',
        },
        {
            title: 'BTS',
            timeRange: '2021 - 2023',
            description: 'Consultant & Coach',
            image: 'BTS_Group.png',
        },

        
    
    ],
    linkedIn: 'https://www.linkedin.com/in/josefinamichelini',
    pdfEs: '/JosefinaMicheliniCV_es.pdf',
    pdfEn: '/JosefinaMicheliniCV_en.pdf'

}

export default micheliniData;