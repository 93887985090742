<template>
    <v-app-bar>
      <template v-slot:prepend>

        <v-btn variant="text" class="ml-2" color="#FFFFFF" size="x-large" to="/">
          Dos Letras
  
        </v-btn>
      </template>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          v-if="mdAndUp"
        >

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-select
          v-model="language"
          :items="Object.keys(locales)"
          single-line
          density="compact"
          variant="outlined"
          style="min-width: 160px; max-width: 160px"
          @update:modelValue="changeLocale(language)"
          v-if="lgAndUp"
        /> 
        <template v-slot:append v-if="smAndDown">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" />
        </template>       
    </v-app-bar>
    <v-navigation-drawer
          v-model="drawer"
          temporary
          v-if="smAndDown"
        >
          <v-list>
            <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
          >

            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { defineComponent } from 'vue';
import { useDisplay } from 'vuetify'

export default defineComponent({
    name: 'AppBar',
    data () {
      return {
        drawer: false,
        items: [
          { title: 'message.about_us', to: '/about' },
          // { title: 'message.skills', to: '/skills' },
          // { title: 'message.projects', to: '/projects' },
          // { title: 'message.timeline', to: '/timeline' }
        ],
        locales: {'Español': 'es', 'English': 'en'},
        language: ''
      }
    },
    methods : {
      changeLocale: function(language){
        return this.$i18n.locale = this.locales[language]
      },
      getLanguage: function(){
        let locale = this.$i18n.locale
        for (const [key, value] of Object.entries(this.locales)) {
          if (value === locale) {
            return key
          }
        }
      },
    },
    setup () {
      // Destructure only the keys we want to use
      const { smAndDown, mdAndUp, lgAndUp } = useDisplay()

      return { smAndDown, mdAndUp, lgAndUp }
    },
    beforeMount(){
      this.language = this.getLanguage()
    },
})
</script>

<style>

</style>