<template>
    <v-footer
      class="bg-surface text-center d-flex flex-column"
    >
      <div>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          :icon="icon"
          class="mx-4"
          variant="text"
        ></v-btn>
      </div>
  
      <div class="pt-0">
      </div>
  
      <v-divider></v-divider>
  
      <div>
        {{ new Date().getFullYear() }} — <strong>Dos Letras</strong>
      </div>
    </v-footer>
  </template>


  <script>
    
  </script>
  
  <style>
  .empty-space {
    height: 200px; /* Adjust the height as needed */
    background-color: rgb(var(--v-theme-surface));
  }
  </style>

<script>
    import { defineComponent } from 'vue';




    export default defineComponent({
        name: 'Footer',
        data: () => ({
        icons: [
        //   'mdi-facebook',
        //   'mdi-twitter',
        //   'mdi-linkedin',
        //   'mdi-instagram',
        ],
      }),
    })
</script>
