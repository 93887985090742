const kleinData = {
    fullname: 'Eugenia Klein',
    name: 'Eugenia',
    surname: 'Klein',
    mail:'eugenia.klein@gmail.com',
    projects:[
        {
            title: 'Harteneck y Lopez',
            timeRange: '1989 - 1991',
            description: 'Trabajos de Consultoría',
            image: 'bg.jpeg',
          },
          {
            title: 'El Trece TV',
            timeRange: '1992 - 1994',
            description: 'Asistente Director General',
            image: 'Canal13.png',
          },
          {
            title: 'Video Cable Comunicación (VCC)',
            timeRange: '1995 - 1998',
            description: 'Corporate Marketing Manager',
            image: 'vcc.webp',
          },
          {
            title: 'Motorola',
            timeRange: '1999 - 2000',
            description: 'Project Leader',
            image: 'motorola.svg',
          },
          {
            title: 'Panamerican Sports Network (PSN)',
            timeRange: '2001 - 2002',
            description: 'Marketing Manager',
            image: 'psn.png',
          },
          {
            title: 'Instituto Argentino de Telecomunicaciones (IAT)',
            timeRange: '2003 - 2008',
            description: 'Socia',
            image: 'argentina.png',
          },
          {
            title: 'INADI',
            timeRange: '2008 - 2014',
            description: 'Programa red de empresas por la diversidad',
            image: 'Logo-inadi.png',
          },
          {
            title: 'Universidad de Buenos Aires (UBA)',
            timeRange: '2008 - Actualidad',
            description: 'Área de pasantias y capacitación en la Facultad de Ciencias Económicas',
            image: 'UBA.png',
          },

    ],

    linkedIn: 'https://www.linkedin.com/in/eugeniaklein/',
    pdfEs: '/EugeniaKleinCV_es.pdf',
    pdfEn: '/EugeniaKleinCV_en.pdf',
}

export default kleinData;