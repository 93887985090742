import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import SkillsView from '../views/SkillsView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import TimelineView from '../views/TimelineView.vue'
import ITBAView from '../views/ITBAView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  // {
  //   path: '/skills',
  //   name: 'skills',
  //   component: SkillsView
  // },
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: ProjectsView
  // },
  // {
  //   path: '/timeline',
  //   name: 'timeline',
  //   component: TimelineView
  // },
  // {
  //   path: '/itba',
  //   name: 'itba',
  //   component: ITBAView
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
