<template>
  <v-container fluid>
      <v-row dense>
        <v-col xs="12" sm="6" md="4" lg="3" xl="2" cols="12" align="center" v-for="skill in skills">
            <SkillCard :title=$t(skill.title) :icon=skill.icon :description=$t(skill.description) />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import SkillCard from '../components/SkillCard.vue'

// Components

export default defineComponent({
  name: 'SkillsView',
  components: {
    SkillCard
  },
  data () {
    return {
      skills: [
          { 
              title: 'message.ai_ml', 
              icon: 'mdi-brain', 
              description: 'message.ai_ml_desc',
          },
          
          { 
              title: 'message.python', 
              icon: 'mdi-language-python',
              description: 'message.python_desc',
          },
          
          { 
              title: 'message.flutter', 
              icon: 'mdi-cellphone-link',
              description: 'message.flutter_desc',
          },
          
          { 
              title: 'message.java', 
              icon: 'mdi-language-java',
              description: 'message.java_desc',
          },
          
          { 
              title: 'message.c', 
              icon: 'mdi-language-c',
              description: 'message.c_desc',
          },
          
          { 
              title: 'message.unity', 
              icon: 'mdi-unity',
              description: 'message.unity_desc',
          },
          
          { 
              title: 'message.cloud_computing', 
              icon: 'mdi-google-cloud',
              description: 'message.cloud_computing_desc',
          },
          
          { 
              title: 'message.sql', 
              icon: 'mdi-database',
              description: 'message.sql_desc',
          },

          { 
              title: 'message.non_relational_db', 
              icon: 'mdi-database',
              description: 'message.non_relational_db_desc',
          },


          
          { 
              title: 'message.vue', 
              icon: 'mdi-vuejs',
              description: 'message.vue_desc',
          },

          { 
              title: 'message.spring', 
              icon: 'mdi-leaf',
              description: 'message.spring_desc',
          },

          {
            title: 'message.haskell',
            icon: 'mdi-language-haskell',
            description: 'message.haskell_desc',
          },
      ]
    }
  }
});
</script>