const projects = [
    {
        title: 'Instituciones Educativas', 
        image: 'education.jpeg' ,
        description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        url: 'https://www.linkedin.com/in/cecilia-casas-1a1b1b1b/',
        projectItems: [
            {
                image: 'educativa3.jpeg',
                title: 'Cocaching',
                description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'https://www.linkedin.com/in/cecilia-casas-1a1b1b1b/'
            },
            {
                image: 'educativa4.jpeg',
                title: 'Gestión de las emociones',
                description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            },
            {
                image: 'educativa1.jpeg',
                title: 'Liderazgo',
                description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            },
            {
                image: 'educativa2.jpeg',
                title: 'Ikigai',
                description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/'
            },

        ]
    
    },

    {
        title: 'Empresas Familiares', 
        image: 'family.jpeg' ,
        description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/',
        projectItems: []
    },

    {
        title: 'Organizaciones', 
        image: 'corporation.jpeg' ,
        description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/',
        projectItems: []
    },

    {
        title: 'Workshops a tu medida', 
        image: 'bg.jpeg' ,
        description: 'lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        url: 'https://www.linkedin.com/in/joaquin-garcia-del-rio-1b1b1b1b/',
        projectItems: []
    }
    
]


export default projects;