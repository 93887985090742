<template>
  <v-app style="min-height: 100vh; min-width: 100vw;">
    <AppBar/>    
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';

export default {
  name: 'App',

  components: {
    AppBar,
  },
};
</script>
