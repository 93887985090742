<template>
    <v-card
      class="mx-4 my-4"
      max-width="400"
      target="_blank"
      variant="plain"

    >
        <v-img
        :src="require('@/assets/' + this.image )"
        height="200px"
        cover
        ></v-img>
      <v-card-title>
        <span class="text-h5 text-left font-weight-bold">{{$t(this.title)}}</span>
      </v-card-title>
    </v-card>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProjectCard',
    props: ['title', 'image', 'description', 'url'],
})
</script>